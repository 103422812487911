import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import OneClickButton from '../../../../components/form/button';
import Table from '../../../../components/table/Table';
import { PanelPage } from '../../../../components/pages/pages';
import { getVolumes } from '../../../../app/store/actions/outboundOrderVolumes';
import { getUserPrinterOptions } from '../../../../app/store/actions/printers';
import { printConferenceVolumeLabel } from '../../../../app/store/actions/labels';
import ROUTES from '../../../../config/routes';

class LabelVolumeForm extends React.PureComponent {
  constructor() {
    super();
    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE1371' /* Etiquetas */) },
      { value: I18n.t('BEE1922' /* Etiqueta Volume */), active: true },
    ];

    this.state = {
      printerOptions: [],
      showPrinterDialog: false,
      printerSelected: null,
      outboundOrderNumber: '',
      volumesList: [],
      defaultPageSize: 5,
      pageSizeOptions: [5, 10],
      defaultSorted: [{ id: '', desc: false }],
      checked: {},
      checkedVolumeIds: [],
      showModalVolumeQuantity: false,
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE1270' /* Imprimir */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={this.state.checked[row.original.id] || false}
              onChange={() => this.singleSelection(row.original)}
            />
          </div>
        ),
      }, {
        Header: I18n.t('BEE1922' /* Etiqueta Volume */),
        accessor: 'id',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE1811' /* Sequência */),
        accessor: 'sequence',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE497' /* Embalagem */),
        accessor: 'packageCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE564' /* Peso Bruto */),
        accessor: 'grossWeight',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE1812' /* Quantidade de produtos */),
        accessor: 'products',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{row.value.length}</span>
          </div>
        ),
      },
    ];
  }

  async componentDidMount() {
    this.getUserPrinterOptions();
    document.getElementById(1).focus();
    document.getElementById(1).select();
  }

  keypressConfirmPicking = (e) => {
    if (e.keyCode === 13) {
      this.getVolumesList();
    }
  };

  getUserPrinterOptions = async () => {
    const printerInformations = await this.props.getUserPrinterOptions();

    const { userPrinter, printerOptions } = printerInformations;
    const { printerSelected } = this.state;

    this.setState({
      printerOptions,
      printerSelected: (printerSelected === null)
        ? userPrinter : (printerSelected !== userPrinter)
          ? printerSelected : userPrinter,
    });
  };

  setValue = async (attr, value) => {
    if (attr === 'amount') {
      if (parseInt(value, 10) >= 1) {
        this.setState({
          [`${attr}`]: value,
        });
      }
    } else {
      this.setState({
        [`${attr}`]: value,
      });
    }
  };

  hidePrinterDialog = () => {
    this.setState({
      showPrinterDialog: false,
      printerSelected: null,
    });
  };

  singleSelection = (selection) => {
    const { checked } = this.state;
    checked[selection.id] = !checked[selection.id];
    this.setState({ checked: { ...checked } });
  };

  multipleSelection = (selectionOption) => {
    const { checked } = this.state;
    const data = this.reactTable.getResolvedState().sortedData;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index += 1) {
        const element = data[index]._original;
        checked[element.id] = selectionOption;
      }
      this.setState({ checked: { ...checked } });
    }
  };

  validChecked = () => {
    const { checked } = this.state;
    if (!Object.keys(checked).length) {
      addNotification(
        'danger',
        I18n.t('BEE1922' /* Etiqueta Volume */),
        I18n.t('BEE2092' /* Nenhum volume selecionado */),
        'top-right',
      );
    } else {
      const checkedVolumeIds = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in checked) {
        if (checked[key]) {
          checkedVolumeIds.push(key);
        }
      }
      if (checkedVolumeIds.length) {
        this.setState({
          checkedVolumeIds: [...checkedVolumeIds],
          showModalVolumeQuantity: true,
        });
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1922' /* Etiqueta Volume */),
          I18n.t('BEE2092' /* Nenhum volume selecionado */),
          'top-right',
        );
      }
    }
  };

  checkPrinters = () => {
    this.setState({ showModalVolumeQuantity: false });
    const { printerOptions, printerSelected } = this.state;
    const { outboundOrderNumber } = this.state;

    if (outboundOrderNumber) {
      if (printerOptions.length === 0 && printerSelected === null) {
        addNotification(
          'danger',
          I18n.t('BEE1922' /* Etiqueta Volume */),
          I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
          'top-right',
        );
      } else if (printerOptions && printerOptions.length > 1) {
        this.setState({
          showPrinterDialog: true,
        });
      } else {
        this.printOut();
      }
    }
  };

  getVolumesList = async () => {
    const { outboundOrderNumber } = this.state;

    if (!outboundOrderNumber) {
      addNotification(
        'danger',
        I18n.t('BEE1922' /* Etiqueta Volume */),
        `${I18n.t('BEE1924' /* Informe a etiqueta volume */)}!`,
        'top-right',
      );
    } else {
      try {
        const volumesList = await this.props.getVolumes(outboundOrderNumber);
        if ((volumesList && volumesList.success === false) || !volumesList.length) {
          addNotification(
            'danger',
            I18n.t('BEE1922' /* Etiqueta Volume */),
            `${I18n.t('BEE1930' /* Documento de saída não encontrado */)}!`,
            'top-right',
          );
          this.setState({ volumesList: [] });
        } else {
          addNotification(
            'success',
            I18n.t('BEE1922' /* Etiqueta Volume */),
            I18n.t('BEE1932', { 0: volumesList.length } /* %{0} volume(s) encontrado(s) */),
            'top-right',
          );
          this.setState({
            volumesList,
          });
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;

          addNotification('danger', I18n.t('BEE1922' /* Etiqueta Volume */), messageError, 'top-right');
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1922' /* Etiqueta Volume */),
            `${I18n.t('BEE1930' /* Documento de saída não encontrado */)}!`,
            'top-right',
          );
        }
        this.setState({ volumesList: [] });
      }
    }
  };

  printOut = async () => {
    const { printerSelected, printerOptions, checkedVolumeIds } = this.state;
    this.setState({ showPrinterDialog: false, checkedVolumeIds: [], checked: {} });
    try {
      const selected = (printerSelected === null) ? printerOptions[0].value : printerSelected;

      const result = await this.props.printConferenceVolumeLabel(selected, checkedVolumeIds);
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1922' /* Etiqueta Volume */),
          I18n.t('BEE1441' /* Erro ao tentar imprimir */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE1923' /* Impressão Volume */),
          I18n.t('BEE1427' /* Etiquetas enviadas para impressora ! */),
          'top-right',
        );
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE1922' /* Etiqueta Volume */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1922' /* Etiqueta Volume */),
          I18n.t('BEE1441' /* Erro ao tentar imprimir */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      outboundOrderNumber, showPrinterDialog, printerSelected, printerOptions, checkedVolumeIds,
      defaultPageSize, defaultSorted, pageSizeOptions, volumesList, showModalVolumeQuantity,
    } = this.state;
    return (
      <>
        <PanelPage
          breadcrumb={this.breadcrumb}
          noButton
          title={I18n.t('BEE1922' /* Etiqueta Volume */)}
          wikiHelp={ROUTES.MOVEMENT_LABEL_VOLUME_HELP}
          content={(
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <div style={{ marginTop: 30 }} onSubmit={(e) => e.preventDefault()}>
                <Form
                  noPanel
                  leftType
                  setValue={this.setValue}
                  inputs={(formContext) => ([
                    formContext.createInput(
                      outboundOrderNumber,
                      'outboundOrderNumber',
                      `${I18n.t('BEE1904' /* Documento de Saída */)}:`,
                      I18n.t('BEE1924' /* Informe o documento de saída */),
                      'text',
                      true,
                      false,
                      undefined,
                      undefined,
                      this.keypressConfirmPicking,
                      1,
                    ),
                  ])}
                />
              </div>
              {(showPrinterDialog && (
              <SweetAlert
                confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="default"
                title={I18n.t('BEE1324' /* Selecionar Impressora */)}
                onConfirm={() => this.printOut()}
                onCancel={() => this.hidePrinterDialog()}
              >
                <div className="mt-4">
                  <Form
                    noPanel
                    setValue={this.setValue}
                    inputs={(formContext) => ([
                      formContext.createSelect(
                        printerSelected,
                        'printerSelected',
                        I18n.t('BEE328' /* Impressora */),
                        printerOptions,
                        '',
                        '',
                        12,
                        true,
                      ),
                    ])}
                  />
                </div>
              </SweetAlert>
              ))}
            </div>
          )}
          footerContent={(
            <button
              type="button"
              className="btn btn-120 btn-info p-5 m-5"
              onClick={() => this.getVolumesList()}
            >
              {I18n.t('BEE407' /* Buscar */)}
            </button>
          )}
        />
        <Table
          ref={(r) => {
            this.reactTable = r && r.reactTable;
          }}
          headerTitle=" "
          actionButtons={(
            !!volumesList.length && (
              <div className="ml-auto">
                <button
                  type="button"
                  className="btn btn-120 btn-secondary p-5 m-5"
                  onClick={() => this.multipleSelection(true)}
                >
                  {I18n.t('BEE1908' /* Marcar Todos */)}
                </button>
                <button
                  type="button"
                  className="btn btn-120 btn-secondary p-5 m-5"
                  onClick={() => this.multipleSelection(false)}
                >
                  {I18n.t('BEE1909' /* Desmarcar Todos */)}
                </button>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={() => this.validChecked()}
                >
                  {I18n.t('BEE1270' /* Imprimir */)}
                </OneClickButton>
              </div>
            )
          )}
          panelHeaderProps={{ noButton: true, children: I18n.t('BEE405' /* Volumes */).toUpperCase() }}
          filterable
          expander
          columns={this.tableColumns}
          data={volumesList}
          pageSizeOptions={pageSizeOptions}
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalVolumeQuantity
          && (
          <SweetAlert
            info
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.checkPrinters()}
            onCancel={() => this.setState({ showModalVolumeQuantity: false })}
          >
            {I18n.t('BEE2093', { 0: checkedVolumeIds.length } /* %{0} etiqueta de volumes selecionados */)}
          </SweetAlert>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  printConferenceVolumeLabel: (
    printerCode,
    checkedVolumeIds,
  ) => dispatch(printConferenceVolumeLabel(printerCode, checkedVolumeIds)),
  getVolumes: (outboundOrderNumber) => dispatch(getVolumes(outboundOrderNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelVolumeForm);
