import InboundCheckRequests from '../../server/inboundCheck';
import SerialGroupRequests from '../../server/serialGroup';
import { addLoading, removeLoading } from './loading';

export const setInboundCheckInvoices = (inboundCheckInvoices) => ({
  type: 'SET_INBOUND_CHECK_INVOICES',
  inboundCheckInvoices,
});

export const setInboundCheckCreateEanState = (createEanState) => ({
  type: 'SET_CREATE_EAN_STATE',
  createEanState,
});

export const setReceivesExpired = (receivesExpired) => ({
  type: 'SET_RECEIVES_EXPIRED',
  receivesExpired,
});

export const getInboundCheckInvoices = (startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const inboundOrders = await InboundCheckRequests.getInboundCheckInvoices(startDate, endDate);
    dispatch(setInboundCheckInvoices(inboundOrders));
  } finally {
    dispatch(removeLoading());
  }
};

export const getInboundCheckInvoice = (invoiceId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundCheckRequests.getInboundCheckInvoice(invoiceId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInboundCheckInvoiceByNumber = (orderNumber) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundCheckRequests.getInboundCheckInvoiceByNumber(orderNumber);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInboundCheckProductByEan = (ean, invoiceId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundCheckRequests.getInboundCheckProductByEan(ean, invoiceId);
  } finally {
    dispatch(removeLoading());
  }
};

export const getSerialGroup = (barCode, inboundOrderId, uz = '') => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await SerialGroupRequests.getSerialGroup(barCode, 'check', inboundOrderId, uz);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInboundCheckProductByCode = (productCode, invoiceId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await InboundCheckRequests.getInboundCheckProductByCode(productCode, invoiceId);
    dispatch(setReceivesExpired(data.receivesExpired));
    return data;
  } finally {
    dispatch(removeLoading());
  }
};

export const getEmptyStorageAddresses = (branchCode, productCode, filterStatus = false) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundCheckRequests.getEmptyStorageAddresses(branchCode, productCode, filterStatus);
  } finally {
    dispatch(removeLoading());
  }
};

export const putConfirmCount = (
  inboundOrderProductId,
  count,
  damagedCount,
  baptism,
  invoiceId,
  lots,
  netWeight,
  volume,
  grossWeight,
  width,
  length,
  height,
  divergenceNote,
  suggestedAddress,
  partialCheck,
  serialGroupId,
  stage,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await InboundCheckRequests.putConfirmCount(
      inboundOrderProductId,
      count,
      damagedCount,
      baptism,
      invoiceId,
      lots,
      netWeight,
      volume,
      grossWeight,
      width,
      length,
      height,
      divergenceNote,
      suggestedAddress,
      partialCheck,
      serialGroupId,
      stage,
    );
  } finally {
    dispatch(removeLoading());
  }
};
