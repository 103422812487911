import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import { createEan, getEan, updateEan } from '../../../../app/store/actions/eans';
import { setInboundCheckCreateEanState } from '../../../../app/store/actions/inboundCheck';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import helpersBarCode from '../../../../helpers/barCode';
import ROUTES from '../../../../config/routes';
import { getProductByCode } from '../../../../app/store/actions/products';
import { getPackagesList } from '../../../../app/store/actions/packages';
import Select from '../../../../components/form/select';

class EanForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageTitle: '',
      pageType: 'detail',
      inputProductCode: this.createInput,
      productCode: '',
      disableProductCode: true,
      productDescription: '',
      barCodeType: '',
      disableBarCodeType: true,
      factor: 0,
      disableFactor: true,
      barCode: '',
      disableBarCode: true,
      packageCode: null,
      packageName: '',
      listPackages: [],
      disablePackage: true,
      mainBarCode: false,
      disableMainBarCode: true,
      note: '',
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      cancelPath: null,
    };
  }

  async componentDidMount() {
    await this.setScreenMode();
    if (this.state.mode !== 'create') {
      await this.getEanSelected();
    }
  }

  selectTypePage = () => {
    let pageType = '';
    let pageTitle = '';

    if (this.props.isCreate) {
      pageType = 'create';
      pageTitle = I18n.t('BEE826' /* Novo EAN */);
    } else if (this.props.isCopy) {
      pageType = 'copy';
      pageTitle = I18n.t('BEE826' /* Novo EAN */);
    } else if (this.props.isEdit) {
      pageType = 'edit';
      pageTitle = I18n.t('BEE832' /* Editar EAN */);
    } else if (this.props.isDetail) {
      pageType = 'detail';
      pageTitle = I18n.t('BEE833' /* Detalhes EAN */);
    }
    return { pageType, pageTitle };
  };

  setScreenMode = async () => {
    let listPackages = [];
    let refMain = '';
    try {
      await this.props.getPackagesList();
      listPackages = (this.props.packagesList || []).map((element) => ({
        value: element.code,
        label: `${element.code} - ${element.name}`,
      }));
    } catch (error) { /* null */ }
    const { pageType, pageTitle } = this.selectTypePage();
    const updateData = { pageTitle, pageType, listPackages };
    if (pageType === 'create' || pageType === 'copy') {
      updateData.disableProductCode = false;
      updateData.showCreate = true;
      updateData.showCancel = true;
      updateData.showUpdate = false;
      updateData.inputProductCode = this.createInputButton;
      refMain = 'barCode-1';
      if (pageType === 'create' && this.props.inboundCheckCreateEanState) {
        updateData.productCode = this.props.inboundCheckCreateEanState.productCode;
        updateData.disableProductCode = true;
        updateData.productDescription = this.props.inboundCheckCreateEanState.descriptionFull;
        updateData.disableBarCodeType = false;
        updateData.disableBarCode = true;
        updateData.disableMainBarCode = true;
        updateData.disablePackage = true;
        updateData.disableNote = true;
        updateData.cancelPath = ROUTES.INBOUND_CHECK;
        updateData.inputProductCode = this.createInput;
        refMain = 'barCode-2';
      }
    } else if (pageType === 'edit') {
      updateData.disableProductCode = true;
      updateData.disableBarCodeType = false;
      updateData.disableBarCode = false;
      updateData.disablePackage = false;
      updateData.disableMainBarCode = false;
      updateData.disableNote = false;
      updateData.showCreate = false;
      updateData.showUpdate = true;
      updateData.showCancel = true;
      updateData.inputProductCode = this.createInput;
      refMain = 'barCode-2';
    }
    await this.setState({ ...updateData });
    if (refMain) this[refMain].focus();
  };

  getEanSelected = async () => {
    if (this.props.location.state && this.props.location.state.ean) {
      const productEan = this.props.location.state.ean;

      const eanSelected = await this.props.getEan(productEan.id);

      if (eanSelected) {
        const { listPackages = [], pageType } = this.state;
        const packages = (eanSelected.packageCode) ? listPackages.find(
          (element) => (element.value === eanSelected.packageCode),
        ) : {};
        this.setState({
          productEanId: eanSelected.id,
          productCode: eanSelected.productCode,
          productDescription: eanSelected.product.fullName,
          barCodeType: `${eanSelected.barCodeType}`,
          factor: eanSelected.factor,
          disableFactor: (pageType === 'detail' || pageType === 'copy') ? true : eanSelected.barCodeType === 1,
          barCode: eanSelected.ean,
          mainBarCode: eanSelected.main,
          packageCode: packages.value,
          packageName: packages.label,
          note: eanSelected.note,
        });
      }
    }
  };

  goToElement = async (e, ref, attr) => {
    if (e.keyCode === 13) {
      if (attr === 'productCode') {
        await this.getProductDescription();
        const { pageType, barCodeType } = this.state;
        if (pageType === 'copy') {
          this.setState({
            disableBarCode: false,
            disableMainBarCode: false,
            disableFactor: (barCodeType === '1'),
            disablePackage: false,
            disableNote: false,
          });
        }
      } else if (attr === 'note') {
        this.createEanSubmit();
      }
      const adder = (attr === 'barCodeType' && this.state.barCodeType === '1') ? 2 : 1;
      if (attr === 'mainBarCode') this[`barCode-${ref + adder}`].select.focus();
      else this[`barCode-${ref + adder}`].focus();
    }
  };

  setValue = (attr, value, clean = false) => {
    const updateData = { [`${attr}`]: value };
    if (attr === 'barCodeType') {
      updateData.disableBarCode = false;
      updateData.disableMainBarCode = false;
      updateData.disablePackage = false;
      updateData.disableNote = false;
      if (!value) {
        updateData.factor = 0;
        updateData.disableFactor = true;
        updateData.disableBarCode = true;
        updateData.disableMainBarCode = true;
        updateData.disablePackage = true;
        updateData.disableNote = true;
      } else if (value === '1') {
        updateData.factor = 1;
        updateData.disableFactor = true;
      } else {
        updateData.factor = 1;
        updateData.disableFactor = false;
      }
    }
    if (clean) {
      updateData.productCode = '';
      updateData.disableProductCode = false;
      updateData.productDescription = '';
      updateData.barCodeType = '';
      updateData.disableBarCodeType = true;
      updateData.factor = 0;
      updateData.disableFactor = true;
      updateData.barCode = '';
      updateData.disableBarCode = true;
      updateData.mainBarCode = false;
      updateData.disableMainBarCode = true;
      updateData.packageCode = null;
      updateData.packageName = '';
      updateData.disablePackage = true;
      updateData.disableNote = true;
    }

    this.setState({ ...updateData });
  };

  setValueDrop = async (ref, attr, value) => {
    const updateData = {
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    };
    this.setState({ ...updateData });

    this[`barCode-${ref + 1}`].focus();
  };

  getProductDescription = async () => {
    const { productCode, pageTitle } = this.state;
    try {
      const productDetails = await this.props.getProductDetails(productCode);
      const updateData = {};
      await this.setState({
        disableProductCode: true,
        productDescription: productDetails.fullName,
        disableBarCodeType: false,
        ...updateData,
      });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;
      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification(
          'danger',
          pageTitle,
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          pageTitle,
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  createEanSubmit = async () => {
    const {
      disableProductCode, productCode, barCodeType, factor, barCode, mainBarCode, packageCode, note,
    } = this.state;

    if (!disableProductCode) {
      this.goToElement({ keyCode: 13 }, 1, 'productCode');
    } else {
      try {
        const newEan = await this.props.createEan({
          productCode,
          barCodeType: parseInt(barCodeType, 10),
          factor,
          barCode,
          mainBarCode,
          packageCode,
          note,
        });

        if (newEan) {
          if (newEan.success && newEan.success === false) {
            await addNotification(
              'danger',
              I18n.t('BEE826' /* Novo EAN */),
              I18n.t('BEE827', { 0: barCode } /* Erro ao incluir o EAN %{0} ! */),
              'top-right',
            );
          } else {
            await addNotification(
              'success',
              I18n.t('BEE826' /* Novo EAN */),
              I18n.t('BEE828', { 0: barCode } /* Novo EAN %{0} incluída com sucesso ! */),
              'top-right',
            );

            if (this.props.inboundCheckCreateEanState) {
              const createEanState = { ...this.props.inboundCheckCreateEanState };
              createEanState.newEan = barCode;
              this.props.setInboundCheckCreateEanState(createEanState);
              this.props.history.push(ROUTES.INBOUND_CHECK, { fromPage: 'createEan' });
            } else {
              this.props.history.push(ROUTES.EAN_LIST);
            }
          }
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;

          if (error.details || error.message) {
            await addNotification(
              'danger',
              I18n.t('BEE826' /* Novo EAN */),
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            await addNotification(
              'danger',
              I18n.t('BEE826' /* Novo EAN */),
              I18n.t('BEE827', { 0: barCode } /* Erro ao incluir o EAN %{0} ! */),
              'top-right',
            );
          }
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE826' /* Novo EAN */),
            I18n.t('BEE827', { 0: barCode } /* Erro ao incluir o EAN %{0} ! */),
            'top-right',
          );
        }
      }
    }

  };

  updateEanSubmit = async () => {
    const {
      productEanId, productCode, barCodeType, factor, barCode, mainBarCode, packageCode, note,
    } = this.state;

    try {
      const updEan = await this.props.updateEan({
        productEanId,
        productCode,
        barCodeType: parseInt(barCodeType, 10),
        factor,
        barCode,
        mainBarCode,
        packageCode,
        note,
      });

      if (updEan) {
        if (updEan.success && updEan.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE829' /* Atualizar EAN */),
            I18n.t('BEE830', { 0: barCode } /* Erro ao atualizar o EAN %{0} ! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE829' /* Atualizar EAN */),
            I18n.t('BEE831', { 0: barCode } /* Atualização do EAN %{0} efetuada com sucesso ! */),
            'top-right',
          );

          this.props.history.push({
            pathname: ROUTES.EAN_LIST,
            state: {
              fromPage: 'createEan',
            },
          });
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE829' /* Atualizar EAN */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE829' /* Atualizar EAN */),
            I18n.t('BEE830', { 0: barCode } /* Erro ao atualizar o EAN %{0} ! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE829' /* Atualizar EAN */),
          I18n.t('BEE830', { 0: barCode } /* Erro ao atualizar o EAN %{0} ! */),
          'top-right',
        );
      }
    }
  };

  createInput = (ref, value, attr, label, placeholder, type = 'text', required, disabled, keypressFunction) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">
        {label}
        {' '}
        {required && <span style={{ color: 'red' }}>*</span>}
      </label>
      <div className="px-md-3 col-md-5">
        <input
          ref={(el) => { this[`barCode-${ref}`] = el; }}
          type={type}
          onKeyDown={keypressFunction && ((el) => keypressFunction(el, ref, attr))}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, type === 'number' ? Math.abs(e.target.value) : e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createInputButton = (
    ref,
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    buttonLabel = '',
    buttonFunction = undefined,
  ) => (
    <div className="form-group row m-b-15">
      <label htmlFor={label} className="col-form-label col-md-4">
        {label}
        {' '}
        {required && <span style={{ color: 'red' }}>*</span>}
      </label>
      <div className="input-group col-md-5">
        <input
          ref={(el) => { this[`barCode-${ref}`] = el; }}
          onKeyDown={keypressFunction && ((el) => keypressFunction(el, ref, attr))}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append m-b-5">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.setValue(attr, '', true)}
          >
            <i className="fa fa-times" />
          </button>
          {buttonLabel && buttonFunction && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => buttonFunction()}
            >
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  createTextArea = (ref, value, attr, label, placeholder, rows, required, disabled, keypressFunction) => (
    <div className="form-group row m-b-15">
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="px-md-3 col-md-5">
        <textarea
          ref={(el) => { this[`barCode-${ref}`] = el; }}
          onKeyDown={keypressFunction && ((el) => keypressFunction(el, ref, attr))}
          className="form-control m-b-5"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  createSelect = (ref, value, attr, label, items, disabled, keypressFunction, required) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">
        {label}
        {' '}
        {required && <span style={{ color: 'red' }}>*</span>}
      </label>
      <div className="px-md-3 col-md-5">
        <select
          ref={(el) => { this[`barCode-${ref}`] = el; }}
          onKeyDown={keypressFunction && ((el) => keypressFunction(el, ref, attr))}
          className="form-control"
          value={value}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
          required={required}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

  createSelectDropDown = (ref, value, attr, label, items, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="px-md-3 col-md-5">
        <Select
          ref={(el) => { this[`barCode-${ref}`] = el; }}
          value={value || ''}
          onChange={(el) => this.setValueDrop(ref, attr, el)}
          options={items}
          isClearable
          isDisabled={disabled}
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  render() {
    const {
      pageTitle, inputProductCode, productCode, disableProductCode, productDescription,
      barCodeType, disableBarCodeType, factor, disableFactor, barCode, disableBarCode,
      packageCode, packageName, listPackages, disablePackage, mainBarCode, disableMainBarCode,
      note, disableNote, showCancel, showUpdate, showCreate, cancelPath,
    } = this.state;

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {` ${I18n.t('BEE12' /* Início */)}`}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE377' /* EAN */)}</li>
            <li className="breadcrumb-item active">{pageTitle}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {pageTitle}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>

                  {inputProductCode(
                    1,
                    productCode,
                    'productCode',
                    `${I18n.t('BEE378' /* Código do Produto */)}:`,
                    I18n.t('BEE2454' /* Informe o código do produto */),
                    'text',
                    true,
                    disableProductCode,
                    this.goToElement,
                  )}

                  {this.createTextArea(
                    'productDescription',
                    productDescription,
                    'productFullDescription',
                    `${I18n.t('BEE758' /* Descrição do Produto */)}:`,
                    '',
                    3,
                    false,
                    true,
                  )}

                  {this.createSelect(
                    2,
                    barCodeType,
                    'barCodeType',
                    `${I18n.t('BEE200' /* Tipo */)}:`,
                    [{ value: '', label: '' },
                      ...helpersBarCode.barCodeType(),
                    ],
                    disableBarCodeType,
                    this.goToElement,
                    true,
                  )}

                  {this.createInput(
                    3,
                    Math.abs(factor),
                    'factor',
                    `${I18n.t('BEE2591' /* Fator */)}:`,
                    '',
                    'number',
                    true,
                    disableFactor,
                    this.goToElement,
                  )}

                  {this.createInput(
                    4,
                    barCode,
                    'barCode',
                    `${I18n.t('BEE377' /* EAN */)}:`,
                    I18n.t('BEE3748' /* Informe o Código de Barras */),
                    'text',
                    true,
                    disableBarCode,
                    this.goToElement,
                  )}

                  {this.createSelect(
                    5,
                    mainBarCode,
                    'mainBarCode',
                    `${I18n.t('BEE836' /* O EAN é o principal para o produto */)}:`,
                    helpersBarCode.listMainBarCode(),
                    disableMainBarCode,
                    this.goToElement,
                  )}

                  {this.createSelectDropDown(
                    6,
                    { value: packageCode, label: packageName },
                    'package',
                    `${I18n.t('BEE2355' /* Embalagem Padrão */)}:`,
                    listPackages,
                    disablePackage,
                  )}

                  {this.createInput(
                    7,
                    note,
                    'note',
                    `${I18n.t('BEE135' /* Observação */)}:`,
                    I18n.t('BEE136' /* Informe a observação */),
                    'text',
                    false,
                    disableNote,
                    this.goToElement,
                  )}
                </PanelBody>
                <PanelFooter>
                  <Link to={{
                    pathname: showCancel && cancelPath ? cancelPath : ROUTES.EAN_LIST,
                    state: showCancel && cancelPath ? { fromPage: 'createEan' } : { dice: this.props.location.dice },
                  }}
                  >
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-white p-5 m-5"
                    >
                      {showCancel ? I18n.t('BEE99' /* Cancelar */) : I18n.t('BEE137' /* Voltar */)}
                    </OneClickButton>
                  </Link>
                  {(showCreate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.createEanSubmit()}
                      >
                        {I18n.t('BEE138' /* Criar */)}

                      </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.updateEanSubmit()}
                      >
                        {I18n.t('BEE139' /* Atualizar */)}

                      </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const colourStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#e9ecef' : 'white',
  }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};

const mapStateToProps = (state) => ({
  inboundCheckCreateEanState: state.inboundCheck && state.inboundCheck.createEanState,
  packagesList: state.packages && state.packages.packagesList,
});

const mapDispatchToProps = (dispatch) => ({
  getEan: (productEanId) => dispatch(getEan(productEanId)),
  createEan: (newBarCode) => dispatch(createEan(newBarCode)),
  updateEan: (newBarCode) => dispatch(updateEan(newBarCode)),
  setInboundCheckCreateEanState: (createEanProductCode) => dispatch(
    setInboundCheckCreateEanState(createEanProductCode),
  ),
  getProductDetails: (productCode) => dispatch(getProductByCode(productCode)),
  getPackagesList: () => dispatch(getPackagesList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EanForm));
