import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getMaterialsFamilyList, deleteMaterialFamily } from '../../../../app/store/actions/materialsFamily';

class MaterialFamilyList extends React.Component {
  constructor(props) {
    super(props);

    // this.page = 0;
    // this.pageSize = 10;
    // this.sorted = [];
    // this.filtered = [];

    this.state = {
      showModalDelete: false,
      materialFamilyUpdate: null,
      pages: 0,
    };

    this.defaultSorted = [
      {
        id: 'materialFamilyCode',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showMaterialFamilyDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditMaterialFamily(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyMaterialFamily(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteMaterialFamily(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1082' /* Gerar Plano Corte */),
        accessor: 'cuttingPlan',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option key="all" value="">{I18n.t('BEE793' /* Todos */)}</option>
            <option key="true" value>{I18n.t('BEE172' /* Sim */)}</option>
            <option key="false" value={false}>{I18n.t('BEE173' /* Não */)}</option>
          </select>
        ),
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'unitMeasureName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getMaterialsFamily();
    } else {
      await this.getMaterialsFamily();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      materialFamilyUpdate: null,
    });
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        const { value } = event.target;
        const { keyCode, which } = event;

        if (value !== '' && (keyCode === 13 || which === 13)) {
          onChange(value);
        }
      }}
      onKeyUp={(event) => {
        const { value } = event.target;
        const { keyCode, which } = event;

        if (value === '' && (keyCode === 8 || which === 8)) {
          onChange(value);
        }
      }}
    />
  );

  getMaterialsFamily = async () => {
    await this.props.getMaterialsFamilyList();

    // const pages = await this.props.getMaterialsFamilyList({
    //   page: this.page,
    //   pageSize: this.pageSize,
    //   sorted: this.sorted,
    //   filtered: this.filtered,
    // });

    // this.setState({ pages });
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteMaterialFamily = async (materialFamily) => {
    const result = await this.props.deleteMaterialFamily(materialFamily._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE1113' /* Eliminar Família Material */),
        I18n.t('BEE1116', { 0: materialFamily.code } /* Família Material %{0} eliminada com sucesso! */),
        'top-right',
      );
    }
    await this.getMaterialsFamily();
  };

  showMaterialFamilyDetail = (materialFamily) => {
    this.props.history.push({
      pathname: ROUTES.MATERIAL_FAMILY_DETAIL,
      state: {
        materialFamily: materialFamily._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditMaterialFamily = (materialFamily) => {
    this.props.history.push({
      pathname: ROUTES.MATERIAL_FAMILY_EDIT,
      state: {
        materialFamily: materialFamily._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyMaterialFamily = (materialFamily) => {
    this.props.history.push({
      pathname: ROUTES.MATERIAL_FAMILY_COPY,
      state: {
        materialFamily: materialFamily._original,
      },
    });
  };

  showDeleteMaterialFamily = (materialFamily) => {
    this.setState({ showModalDelete: true, materialFamilyUpdate: materialFamily });
  };

  render() {
    const { materialsFamilyList = [] } = this.props;
    const {
      materialFamilyUpdate, showModalDelete, isLoading, page, totalDataLength,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1080' /* Família de Materiais */)}</li>
          </ol>
        </div>
        <Table
          wikiHelp={ROUTES.MATERIAL_FAMILY_HELP}
          downloadCSV
          headerTitle={I18n.t('BEE1080' /* Família de Materiais */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.MATERIAL_FAMILY_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE1117' /* Incluir Família Material */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getMaterialsFamily,
          }}
          loading={isLoading}
          filterable
          data={materialsFamilyList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          // manual // Server - Side Table
          // pages={pages}
          // onFetchData={(state, instance) => {
          //   this.page = state.page;
          //   this.pageSize = state.pageSize;
          //   this.sorted = state.sorted;
          //   this.filtered = state.filtered;
          //   this.getMaterialsFamily();
          // }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteMaterialFamily(materialFamilyUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE1139', { 0: materialFamilyUpdate.code } /*  A Família Material %{0} será eliminada ! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  materialsFamilyList: state.materialsFamily && state.materialsFamily.materialsFamilyList,
});

const mapDispatchToProps = (dispatch) => ({
  getMaterialsFamilyList: () => dispatch(getMaterialsFamilyList()),
  deleteMaterialFamily: (materialFamilyId) => dispatch(deleteMaterialFamily(materialFamilyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MaterialFamilyList));
