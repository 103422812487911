import moment from 'moment';

import RestService from '../services/rest';

// GET
async function getInboundCheckInvoices(startDate, endDate) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    `inboundCheck/queryInvoices?startDate=${sDate}&endDate=${eDate}`,
  );
}

async function getInboundCheckInvoice(invoiceId) {
  return RestService.getAuthenticated(`inboundCheck/invoice?inboundOrderId=${invoiceId}`);
}

async function getInboundCheckInvoiceByNumber(orderNumber) {
  return RestService.getAuthenticated(`inboundCheck/invoiceByNumber?orderNumber=${orderNumber}`);
}

async function getInboundCheckProductByEan(ean, invoiceId) {
  return RestService.getAuthenticated(`inboundCheck/productByEan?ean=${ean}&inboundOrderId=${invoiceId}`);
}

async function getInboundCheckProductByCode(productCode, invoiceId) {
  return RestService.getAuthenticated(
    `inboundCheck/productByCode?productCode=${productCode}&inboundOrderId=${invoiceId}`,
  );
}

async function getEmptyStorageAddresses(branchCode, productCode, filterStatus = false) {
  return RestService.getAuthenticated(
    `storageAddresses/list/empty?branchCode=${branchCode}&productCode=${productCode}&filterStatus=${filterStatus}`,
  );
}

// PUT
async function putConfirmCount(
  inboundOrderProductId,
  count,
  damageCount,
  baptism,
  invoiceId,
  lots,
  netWeight,
  volume,
  grossWeight,
  width,
  length,
  height,
  divergenceNote,
  suggestedAddress,
  partialCheck,
  serialGroupId,
  stage,
) {
  return RestService.putAuthenticated('inboundCheck/confirmCount', {
    inboundOrderProductId,
    count,
    damageCount,
    baptism,
    invoiceId,
    lots,
    netWeight,
    volume,
    grossWeight,
    width,
    length,
    height,
    divergenceNote,
    suggestedAddress,
    partialCheck,
    serialGroupId,
    stage,
  });
}

export default {
  getInboundCheckInvoices,
  getInboundCheckInvoice,
  getInboundCheckProductByEan,
  getInboundCheckProductByCode,
  getInboundCheckInvoiceByNumber,
  getEmptyStorageAddresses,
  putConfirmCount,
};
