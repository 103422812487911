import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getUnitsMeasureList, deleteUnitMeasure } from '../../../../app/store/actions/unitsMeasure';

class UnitMeasureList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      unitUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showUnitDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditUnit(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyUnit(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteUnit(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getUnitsMeasure();
    } else {
      await this.getUnitsMeasure();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      unitUpdate: null,
    });
  };

  getUnitsMeasure = async () => {
    await this.props.getUnitsMeasureList();
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteUnitMeasure = async (unit) => {
    const result = await this.props.deleteUnitMeasure(unit._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE877' /* Eliminar unidade de medida */),
        I18n.t('BEE878' /* Unidade de medida eliminada com sucesso ! */),
        'top-right',
      );
    }
    await this.getUnitsMeasure();
  };

  showUnitDetail = (unit) => {
    this.props.history.push({
      pathname: ROUTES.UNIT_DETAIL,
      state: {
        unit: unit._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditUnit = (unit) => {
    this.props.history.push({
      pathname: ROUTES.UNIT_EDIT,
      state: {
        unit: unit._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyUnit = (unit) => {
    this.props.history.push({
      pathname: ROUTES.UNIT_COPY,
      state: {
        unit: unit._original,
      },
    });
  };

  showDeleteUnit = (unit) => {
    this.setState({ showModalDelete: true, unitUpdate: unit });
  };

  render() {
    const { unitUpdate, showModalDelete, page } = this.state;
    const { unitsMeasureList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE29' /* Unidades de Medida */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE29' /* Unidades de Medida */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.UNIT_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE879' /* Incluir unidade de medida */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getUnitsMeasure,
          }}
          filterable
          data={unitsMeasureList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteUnitMeasure(unitUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE880', { 0: unitUpdate.code } /*    A impressora %{0} será eliminada ! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  unitsMeasureList: state.unitsMeasure && state.unitsMeasure.unitsMeasureList,
});

const mapDispatchToProps = (dispatch) => ({
  getUnitsMeasureList: () => dispatch(getUnitsMeasureList()),
  deleteUnitMeasure: (unitMeasureId) => dispatch(deleteUnitMeasure(unitMeasureId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UnitMeasureList));
